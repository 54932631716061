.calender_body {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}



.divine_slots_booking_page_main .calender_body {
  background: #c9d2d9;
  padding: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: solid 1px #d7d7d7;
  box-shadow: none !important;
}

.aspireheader {
  font-size: 65px !important;
}

.calender_block {
  max-width: inherit !important;
  width: 30.3% !important;
  margin: 10px 0.5% !important;
  background: #f2f8fa;
  padding: 0.5% !important;
  border: solid 1px #3e508540 !important;
  word-break: break-all;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 2px 1px white inset, 0px -2px 8px white,
    0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
  position: relative;
  z-index: 0;
}

.divine_slots_booking_page_main .calender_block {
  max-width: inherit !important;
  width: 32.3% !important;
  margin: 10px 0.5% !important;
  background: #333334;
  padding: 0.5% !important;
  border: solid 1px #3f4141 !important;
  border-radius: 6px;
  box-shadow: 0 0 10px #000000c4;
  word-break: break-all;
  transition: all 0.2s ease-in-out;
  /* box-shadow: 0px 2px 1px white inset, 0px -2px 8px white,
    0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%); */
  position: relative;
  z-index: 0;
}


.calender_block:hover {
  transform: scale(1.1);
  z-index: 1;
}

.calender_block h2 {
  margin: 0;
  display: block;
  background: #00abff;
  background: linear-gradient(90deg, #00abff 0%, #fc00ff 100%);
  font-size: 18px;
  padding: 8px 5px;
  font-size: 18px;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 6px;
  word-break: break-word;
}



.newColorSchemNew h2 {
  margin: 0;
  display: block;
  background: #00abff;
  background: linear-gradient(90deg, #00abff, #224d9b);
  font-size: 18px;
  padding: 8px 5px;
  font-size: 18px;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 6px;
  word-break: break-word;
}

.calender_block p {
  padding: 6px 0;
  margin: 0;
}

.calender_block p span {
  color: #3f86ce;
}

.calender_block p .innerSpan {
  color: #000;
}

.calender_block .cancelSlotBtnSection {
  margin-top: 10px;
}

.calender_block .slotBookBtn {
  margin: 5px;
}

.calender_block.bookNowModal {
  width: 100% !important;
  background: none;
  box-shadow: none;
  border: none !important;
  word-break: normal;
}

.calender_block.bookNowModal:hover {
  transform: none !important;
}

.formCls .multipleCheckbox .MuiFormControl-root {
  flex: 1 0 100%;
  box-shadow: none;
}

.formCls .eventDetails .MuiFormLabel-root {
  position: relative;
  height: 50px;
  width: auto;
  background: #626fb1;
  text-align: left;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transform: none;
  text-transform: capitalize;
}

.formCls .eventDetails.sunEditor label {
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.formCls .multipleCheckbox .MuiFormControlLabel-label {
  color: #000;
}

.formCls .multipleCheckbox .css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
  color: rgb(0 0 0);
  font-weight: 600;
}

.formCls .singleCheckbox .MuiFormControl-root {
  color: #000;
  flex: 1 0 100%;
  box-shadow: none;
}

.formCls .singleCheckbox .MuiInputLabel-root {
  color: #000;
  font-weight: 600;
}

.formCls .singleCheckbox {
  padding: 0 8px !important;
}

.ContentWrapper {
  width: 1780px;
  margin: 20px auto 0;
  display: block;
}

.connectedemail {
  margin: 0;
  background: #00abff;
  background: linear-gradient(90deg, #00abff 0%, #fc00ff 100%);
  font-size: 18px;
  padding: 18px;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
}

.connectedemail h2 {
  margin: 0;
  margin-bottom: 15px;
}

.calenderinfo {
  width: 450px;
}

.calenderinfo p {
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}

.calenderinfo p span {
  text-transform: initial !important;
}

.buttonsetToolbar {
  margin: 0;
  background: #c9d2d9;
  font-size: 18px;
  padding: 18px;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 20px;
}

.buttonsetToolbar button {
  margin: 5px;
}

.buttonsetToolbar button a,
.buttonsetToolbar a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.buttonsetToolbar button:hover a,
.buttonsetToolbar a:hover {
  color: #000;
}

.formCls {
  margin-top: 20px;
}

.reactTable .calendarAvailabilityTable {
  width: 100%;
}

.reactTable .calendarAvailabilityTable table thead tr th:last-child,
.reactTable .calendarAvailabilityTable table tbody tr td:last-child {
  width: 160px;
  text-align: center;
}

/*--------amitava 5/17/2022-----------*/

.addemailbox {
  position: relative;
}

.addemailbox input {
  margin: 5px 0;
}

.addemail {
  padding-right: 10% !important;
}

.addemailBTN {
  width: 10%;
  height: 36px;
  position: absolute;
  right: 0;
  bottom: 5px;
  border: none;
  background: #08507f;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
}

.modalSUBbtn_wrapper {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.modalSUBbtn_wrapper .modalSUBbtn {
  margin: 2px;
  color: #fff;
  background-color: #1976d2 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.banner_IMG {
  margin: 0 auto;
}

.banner_IMG img {
  width: 100%;
  display: block;
}


.form_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: #c9d2d9;
  border-radius: 6px;
  border: solid 1px #d7d7d7;
  box-shadow: 0 0 2px #c9d2d9;
  padding: 15px;
  margin: 14px 0 0 0;
  align-items: center;
}

.form_wrapper .subBTN Button {
  height: 46px;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  margin-top: 32px;
  line-height: 54px;
  display: block;
  padding: 0;
  border-radius: 100px;
  width: 46px;
  min-width: inherit;
  background: linear-gradient(90deg, #00abff 0%, #fc00ff 100%);
  margin-left: 15px;
}

.subBTN .MuiSvgIcon-root {
  width: 40px;
  height: 48px;
}

.form_wrapper .form_group {
  width: 400px;
  margin: 5px 10px;
}

/* <------------------CSS by Sanket for Time Zone Field-----------------> */

.form_wrapper .timezonecls legend {
  display: none;
}

.form_wrapper .timezonecls .MuiOutlinedInput-notchedOutline {
  top: 0;
}

/* <------------------CSS by Sanket for Time Zone Field-----------------> */

.form_wrapper .form_group .MuiOutlinedInput-root {
  width: 100%;
}

.form_wrapper .form_group label {
  display: block;
  margin-bottom: 10px;
  color: #236bb0;
}

.form_wrapper .form_group .divine_orange_cont {
  font-size: 25px;
  line-height: 30px;
  font-weight: 600;
  color: #ff7e00 !important;
}


.form_wrapper .form_group label.MuiInputLabel-formControl {
  color: #9a9999;
}

.form_wrapper .MuiInputBase-input {
  background: #fff;
}

.submitbtnsection {
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 10px;
}

.submitbtnsection Button[type="submit"] {
  display: inline-block;
}

.BioEnergetics_banner1 {
  /* background-image: url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/BioEnergetics_1BG.jpg);
  background-position: center top; background-size: 100% 100%;
  background-repeat: no-repeat;    */
  background: rgb(17, 132, 192);
  background: linear-gradient(90deg,
      rgba(17, 132, 192, 1) 0%,
      rgba(99, 50, 222, 1) 100%);
  border-radius: 15px;
}

.banner_textblock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 22px 25px;
  min-height: 238px;
}

.banner_textblock .banner_textblock_logo {
  width: 20%;
}
.logo1 {
  display: block;
  max-width: 100%;
}

.courtneyForMedicalWrp .banner_textblock .banner_textblock_logo{
  width: 22%;display: flex;justify-content: space-between;flex-direction: column;align-items: center;
}
.courtneyForMedicalWrp .banner_textblock .banner_textblock_logo .logo1span{background-color: #fff;border-radius: 20px;padding: 20px;}

/* .banner_textblock .banner_textblock_logo span {
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  padding: 10px 14px 5px 10px;
} */

.banner_textblock .banner_textblock_logo span.logo_content {
  background: none;
  text-transform: capitalize;
  color: #fff;
  font-size: 29px;
  text-shadow: 0 0 2px #465a34;
  font-weight: bold;
}

.banner_textblock .banner_textblock_logo span img {
  width: 100%;
}

.banner_textblock .banner_textblock_logo.betoBannerLogo {
  width: 100% !important;
}

.banner_textblock h2 {
  margin: 0;
  padding: 0;
  font-size: 45px;
  color: #ffffff;
  width: 75%;
  text-shadow: 0 0 2px #465a34;
  text-transform: capitalize;
}

.banner_textblock h2 p {
  display: block;
  font-size: 26px;
  color: #000000;
  margin: 0;
  padding: 0;
  padding-top: 12px;
  font-weight: normal;
  text-shadow: none;
}

.BioEnergetics_banner1 .banner_textblock p {
  color: #ffffff;
}

.PECE_banner1 {
  /* background-image: url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_1BG.jpg);
  background-position: center top; background-size: 100% 100%;
  background-repeat: no-repeat;   */
  background: rgb(49, 216, 103);
  background: linear-gradient(90deg,
      rgba(49, 216, 103, 1) 0%,
      rgba(217, 226, 95, 1) 100%);
  border-radius: 15px;
}

.BioEnergetics_banner1_bg {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/BioEnergetics_1_topBG.png),
    url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/BioEnergetics_1_bottomBG.png);
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  border-radius: 15px;
}
.fryrightBg1 {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/fryright_images/home_bg1.jpg);
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  border-radius: 15px;
}




.PECE_banner1_bg {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_1_topBG.png),
    url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_1_bottomBG.png);
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  border-radius: 15px;
}

.prectice_presence_bg {
  background: url(https://all-frontend-assets.s3.amazonaws.com/on-pointe-care/banner_nw_bg_img.webp), #05b3d2 no-repeat;
  /* background-size: 1920px;
  background-position: 100% 100%;
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_1_topBG.png),
  url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_1_bottomBG.png); */
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  border-radius: 15px;
}

.canceledAlready.thankyou-container {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/PeceMarketingThankYouDarkShape.png),
    url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/PeceMarketingThankYouDarkShape2.png);
  background-color: #170036;
  background-position: -85px -158px, 627px 480px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
}

.canceledAlready.thankyou-container .thankyou-block {
  text-align: center;
  line-height: 3px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.canceledAlready.thankyou-container .thankyou-block .content {
  color: white;
  text-align: center;
  line-height: 3px;
  max-width: 800px;
  padding: 0;
}

.canceledAlready.thankyou-container .thankyou-block .content h1 {
  font-size: 36px;
  line-height: 36px;
}

.canceledAlready.thankyou-container .thankyou-block .content p {
  font-size: 22px;
  color: #fff;
  margin: 10px 0px;
}

.modalblock .MuiBox-root {
  /* width: 1285px; */
  border: solid 10px #08507f;
  border-radius: 4px;
  padding: 15px;
}

.modalblock .MuiBox-root .header {
  width: calc(100% + 2px);
  background: #13507f;
  position: absolute;
  top: -1px;
  text-align: center;
  left: -1px;
  height: 35px;
  font-size: 32px;
  line-height: 30px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

/* .modalblock .MuiBox-root h2 {
    margin: 0;
    padding: 0;
    color: #1b4965;
    font-size: 30px;
    line-height: 30px;
} */

.modalblock .MuiBox-root h3 {
  margin: 0;
  padding: 0;
  color: #1b4965;
}

.modalblock .MuiBox-root h4 {
  margin: 0;
  padding: 10px 0;
  color: #000;
  font-size: 18px;
}

.modalblock .MuiBox-root input {
  width: 98.5%;
  height: 36px;
  background: #f3efef;
  border: solid 1px #d1c0c0;
  box-shadow: none;
  padding: 0px 0px 0px 4px;
  color: #000;
  padding-right: 0px !important;
}

.slotModal.modalblock .MuiBox-root {
  padding-top: 35px;
  position: relative;
}

.modalblock .MuiBox-root h3 {
  text-align: center;
  margin-top: 10px;
}

.bookingModal.modalblock .MuiBox-root {
  padding: 0px !important;
}

.bookingModal.modalblock .modalBox {
  max-height: 82vh;
  overflow-y: scroll;
  border: 0px !important;
  height: 100% !important;
  padding: 15px !important;
}

.bookingModal.modalblock .closeModalBtn,
.deleteModal.modalblock .closeModalBtn,
.custommodal.modalblock .CloseModal {
  position: absolute;
  background: #2076d2 !important;
  top: -20px;
  width: 30px;
  color: #fff;
  height: 30px;
  right: -20px;
  min-width: auto;
  border-radius: 50%;
}

/* .deleteModal{
  position: relative;
}
  .deleteModal .modalCloseBtn{
    position: absolute;
  } */

/* <------------------CSS by Sanket for Book Slot Modal-----------------> */

.modalblock .MuiBox-root h2 {
  width: 98.5%;
  margin: 0;
  padding: 10px 15px;
  color: #fff;
  font-size: 27px;
  line-height: 31px;
  text-align: center;
  background: #185c85;
}

/* <------------------CSS by Sanket for Book Slot Modal-----------------> */

/* ------------ Responsive ----------------- */

@media (max-width: 701px) {
  .canceledAlready.thankyou-container .thankyou-block .content {
    max-width: 700px;
    line-height: 20px !important;
  }
}

@media screen and (max-width: 500px) {
  .canceledAlready.thankyou-container .thankyou-block .content h1 {
    font-size: 30px;
    line-height: 30px;
  }

  .canceledAlready.thankyou-container .thankyou-block .content p {
    font-size: 17px;
    margin-bottom: 0px;
  }

  /* <------------------Responsive by Sanket for Book Slot Modal-----------------> */

  .bookingModal.modalblock .MuiBox-root.css-1wnsr1i {
    width: 90%;
    border: solid 6px #08507f;
  }

  .modalblock .MuiBox-root h2 {
    font-size: 22px;
    line-height: 26px;
    padding: 10px;
  }

  .bookingModal.modalblock .modalBox {
    padding: 10px !important;
  }

  .css-46bh2p-MuiCardContent-root {
    padding: 10px;
  }

  /* <------------------Responsive by Sanket for Book Slot Modal-----------------> */
}

/* ---------- Load More ------------- */

.loadMoreSection {
  display: flex;
  justify-content: center;
  margin: 15px 0px;
}

/* ---------- Cancel Slot------------ */

.cancelSlotBtnSection {
  display: flex;
  justify-content: center;
}

/*------------------------------------*/

/* <----------------CSS by Sanket--------------> */

.form_wrapper .two_btn_wrp {
  display: flex;
}

/* <----------------CSS by Sanket--------------> */

@media screen and (max-width: 1800px) {
  .ContentWrapper {
    width: 96%;
  }
}
@media screen and (max-width: 1680px){

  .courtneyForMedicalHeaderWrp{font-size: 55px !important;}
}


@media screen and (max-width: 1460px) {
  .banner_textblock .banner_textblock_logo {
    width: 25%;
  }

  .banner_textblock h2 {
    width: 70%;
  }
}

@media screen and (max-width: 1360px) {
  .banner_textblock .banner_textblock_logo {
    width: 28%;
  }

  .banner_textblock h2 {
    width: 67%;
  }
}

@media screen and (max-width: 1299px){

  .courtneyForMedicalHeaderWrp{font-size: 47px !important;line-height: 53px;}
}



@media screen and (max-width: 1199px) {
  .BioEnergetics_banner1_bg {
    background-image: none;
  }

  .PECE_banner1_bg {
    background-image: none;
  }

  .banner_textblock {
    padding: 15px;
  }

  .banner_textblock {
    display: block;
    text-align: center;
  }

  .banner_textblock .banner_textblock_logo {
    width: auto;
    display: inline-block;
    margin-bottom: 15px;
  }

  .banner_textblock h2 {
    width: auto;
    text-align: center;
  }

  .banner_textblock .banner_textblock_logo span {
    width: auto;
  }

  .banner_textblock .banner_textblock_logo span img {
    width: auto;
    max-width: 90%;
  }

  .banner_textblock h2 {
    font-size: 30px;
  }

  .banner_textblock h2 p {
    font-size: 20px;
  }

  .calender_block {
    width: 49% !important;
    padding: 1.5% !important;
  }

  .calender_block h2 {
    padding: 8px 10px;
  }

  .form_wrapper .subBTN {
    clear: both;
    overflow: hidden;
    margin-top: 10px;
    display: block;
    width: 100%;
    text-align: center;
  }

  .form_wrapper .subBTN Button {
    margin-bottom: 10px;
  }

  .aspireheader {
    font-size: 35px !important;
  }

  .courtneyForMedicalWrp .banner_textblock{display: flex;flex-direction: column;}
  .courtneyForMedicalWrp .banner_textblock .banner_textblock_logo{flex-direction: row;width: 100%;}
  .courtneyForMedicalWrp .banner_textblock .banner_textblock_logo span{width: 48%;}
  .courtneyForMedicalWrp .banner_textblock .banner_textblock_logo .logo1span img{width: 72%;max-width: 100%;}
  .courtneyForMedicalWrp .banner_textblock .banner_textblock_logo .logo2span img{width: 100%;max-width: 100%;
    object-fit: contain;}
    .courtneyForMedicalWrp .banner_textblock .banner_textblock_logo .logo2span{    padding: 20px 20px;}
    .courtneyForMedicalWrp .banner_textblock .banner_textblock_logo .logo1span{display: flex
      ;
          justify-content: center;
          align-items: center;}
          .courtneyForMedicalHeaderWrp{font-size: 35px !important;line-height: 41px;}


  
}



/* Deep */
.prectice_presece_header_text {
  font-size: 73px !important;
}


@media screen and (max-width: 1065px) {
  .form_wrapper .subBTN Button {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 991px) {
  .formCls .formInline .formGroup .countDiv__column--col6 {
    flex: 1 0 98% !important;
  }
}

@media screen and (max-width: 991px) {
  .formCls .formInline .formGroup .countDiv__column--col5 {
    flex: 1 0 99% !important;
  }
}

@media screen and (max-width: 948px) {
  .form_wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .calender_block {
    width: 99% !important;
  }

  .calender_body .calender_block {
    padding: 15px !important;
  }

  .connectedemail {
    flex-wrap: wrap;
  }

  .calender_block .slotBookBtn {
    margin: 5px auto;
    display: block;
  }
}

@media screen and (max-width: 540px) {
  .buttonsetToolbar {
    flex-wrap: wrap;
  }

  .form_wrapper .form_group {
    width: 92%;
  }
}

@media screen and (max-width: 499px){

  .courtneyForMedicalWrp .banner_textblock .banner_textblock_logo{flex-direction: column;}
  .courtneyForMedicalWrp .banner_textblock .banner_textblock_logo span{width: 100%;}
  .courtneyForMedicalWrp .banner_textblock .banner_textblock_logo .logo1span img {
    width: 100%;
    max-width: 100%;
}

.courtneyForMedicalHeaderWrp{font-size: 30px !important;line-height: 36px;}
.courtneyForMedicalDateTimeWrp .MuiDateRangePickerInput-root {flex-direction: column;align-items: normal;}
.courtneyForMedicalDateTimeWrp .MuiDateRangePickerInput-root .MuiFormControl-root{width: 100%;}

}


.logo1span {
  /* max-height: 40%; */
  margin-bottom: 20px;
}

.event_view_modal .content_container {
  font-size: 16px;
}

.modalBox .MuiCardContent-root:last-child {
  padding-bottom: 16px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
  width: 98.5%;
  background-color: #d9edf6;
  margin-bottom: 12px;
}




/* << -----------BISWARUP------------ >> */

/* ////////////USER-FROM-CSS///////////// */
.divineModal .MuiBox-root {
  width: 1285px !important;
}

.MuiInputBase-root input {
  width: 100% !important;

}

.date_field input {
  height: 38px !important;
  width: 99.7% !important;
}

.information_details_sec h1 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  color: #000000;
  margin: 0;
  margin-bottom: 10px;
}

.from_sec_main_wrpr {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}

.from_field {
  width: 48%;
  margin: 6px;
}
.from_field.from_field_fullwidth {
  width: 98%;
}
.from_field .MuiFormControl-root {
  width: 100%;
  padding: 0 !important;
  /* margin-top: 10px; */
}

.from_field label {
  top: -8px;
}
.divineModal.modalblock .from_field label {
  top: 0;
}
.divineModal.modalblock .from_field label.MuiInputLabel-shrink {
  display: none;
}
.divineModal.modalblock .from_field legend {
  display: none;
}
.divineModal.modalblock .from_field .MuiOutlinedInput-notchedOutline {
border: none;
}

.from_field .heading_sub {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  color: #3f86ce;
  /* margin-bottom: 10px; */
}

.from_field .relation_status_wrpr {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: unset;
}

.from_field .relation_status_wrpr .status_sec {
  width: fit-content;
}

.from_field.sub_sec label {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  color: #3f86ce;
}

.from_field textarea {
  width: 100%;
  border: 1px solid #6e6e6e;
  padding: 0 !important;
  resize: none !important;
}

.from_field .MuiFormControlLabel-root {
  margin: 0;
  margin-bottom: 15px;
}

/* .from_field .MuiSwitch-sizeMedium {
  border-radius: 50px;
  box-shadow: 2px 2px 10px -5px #5a5a5a8c, -2px -2px 10px -5px #ffffffe0;
} */
::-webkit-scrollbar {
  display: none;
}

.information_details_sec {
  width: 98.5%;
}

.note_section_main {
  width: 98.5%;
}

.user_from_main_wrpr {
  width: 98.5%;
  display: flex;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-style: solid;
  border-image: linear-gradient(90deg, #00000000, #8d8d8db5 45%, #8d8d8db5 55%, #00000000) 10;
  margin-bottom: 15px;
}

.information_details_sec.prsnl_infrm_main {
  width: 49%;
}

.prsnl_infrm_main .from_field {
  width: 100%;
}

.from_field .MuiSelect-select {
  border: 1px solid #6e6e6e;
  border-radius: 0;
}

.divineColourSchem h2 {
  background: #ff7d00 !important;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.253);
  box-shadow: 0 0 6px #000000c4;
}

.divineColourSchem p span {
  color: #ff7e00 !important;
  font-weight: 600;
}
.divineslotbookbtn{
  background: linear-gradient(#636161, #222);
  border: 1px solid #b0adad;
  box-shadow: 2px 2px 10px -5px #000;
}
.form_wrapper .divinesubBTN Button {
  background: linear-gradient(180deg, rgba(85,86,86,1) 0%, rgba(44,45,45,1) 100%);
  border: none !important;
  border-radius: 6px;
  box-shadow: 0 0 6px #000000bd;
  padding: 10px;
  width: 58px;
  height: 58px;
}

.divinesubBTN .MuiSvgIcon-root {
  width: 40px;
  height: 40px;
  color: #e97503;
}

.divine_slots_booking_page_main .banner_textblock {
  justify-content: space-around;
}

.autocompleteCls .MuiOutlinedInput-root{
  padding: 0 !important;
}

.autocompleteCls input{
  height: 20px !important;
}

.modalblock.divineModal .MuiBox-root {
  background: #232324;
}
.modalblock.divineModal .MuiBox-root h2 {
  background: #ff7d00 !important;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  color: #ffffff;
  text-wrap: balance;
  text-transform: uppercase;
}
.divineModal .modalSUBbtn_wrapper .modalSUBbtn {
  display: flex;
  background: linear-gradient(180deg, rgba(85, 86, 86, 1) 0%, rgba(45, 45, 46, 1) 100%);
  border: 1px solid #4c4e50;
  /* margin: 0 0 0 auto; */
}
.divineModal .calender_block p span {
  color: #ff7e00 !important;
  font-weight: 600;
}
.divineModal .from_field.sub_sec label {
  color: #9a9999 !important;
  font-weight: 600;
}
.divineModal .from_field .heading_sub {
  color: #ff7e00 !important;
  font-weight: 600;
}
.divineModal .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #f37800;
}
.divineModal .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
  width: 100%;
  background: #e5ceb8;
}
.divineModal .MuiTypography-body1.css-drtazr-MuiTypography-root {
  color: #000000;
  font-weight: 600;
}
.divineModal .MuiTypography-body1.css-drtazr-MuiTypography-root strong {
  color: #db0202;
}
.modalblock.divineModal .MuiBox-root {
  border: 1px solid #464748;
  border-radius: 10px;
}
.bookingModal.modalblock.divineModal .closeModalBtn {
  background: #ff7e00 !important;
  font-weight: 700;
  color: #000000;
}
.divineModal .css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #ff9024;
}
.divineModal .css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #ff9024;
}
.divineModal .shareholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.divineModal.modalblock .MuiBox-root input {
  width: 49.5%;
  color: #9a9999;
  background: unset;
    border: none;
    padding: 0 10px;
}
.divineModal .information_details_sec h1 {
  color: #ff7e00;
  padding-left: 10px;
}
.divineModal .from_field .MuiSelect-select {
  color: #9a9999;
  background: #000000;
  border: none;
  border-radius: 4px;
}
.divineModal .MuiSvgIcon-fontSizeMedium {
  color: #ff7e00 !important;
}
/* .divineModal .information_details_sec {
  width: calc(100% - 12px);
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.divineModal .from_field label {
  color: #9a9999;
  margin-bottom: 6px;
}
.divineModal .from_field.sub_sec textarea {
  color: #9a9999;
  background: #000000;
  border: 1px solid #484b4b;
}
.divineModal .note_section_main textarea {
  color: #9a9999;
  background: #000000;
  border: 1px solid #484b4b;
}
.divineModal.modalblock .MuiBox-root h4 {
  color: #ff7e00;
}
.divineModal .MuiSwitch-thumb {
  background: #ff7e00 !important;
}
.divineModal .calender_block p {
  font-weight: 600;
  color: #c6c6c6;
}

.divineModal .from_field.date_field input::-webkit-calendar-picker-indicator {
  filter: invert(50%) sepia(80%) saturate(200%) hue-rotate(180deg) grayscale(100%);
  cursor: pointer;
}
.divineModal .autocompleteCls .MuiOutlinedInput-input {
  height: 36px !important;
}


/* .banner_textblock .banner_textblock_logo.divine_logo span {
  display: block;
  width: 100%;
  background: #e9e9e954;
  border-radius: 50%;
  box-shadow: 2px 2px 15px -5px #000000e0, -2px -2px 15px -5px #ffffffde;
  padding: 10px;
  padding-bottom: 2px;
  margin-bottom: 0;
} */
.BioEnergetics_banner1.divine_sec {
  background: url(https://all-frontend-assets.s3.us-east-1.amazonaws.com/transcendentpagan/assets/images/divine_slots_booking_top_bnr_right_img.webp), url(https://all-frontend-assets.s3.us-east-1.amazonaws.com/transcendentpagan/assets/images/divine_slots_booking_top_bnr_bg_img.webp), #000000;
  background-repeat: no-repeat;
  background-size: 815px, 100% 100%, cover;
  background-position: bottom right -175px, top left, center;
  border-radius: 0;
  border-bottom: 1px solid #313232;
}
.divine_sec .BioEnergetics_banner1_bg {
  background: none;
}
.BioEnergetics_bnr_heading_sec {
  position: relative;
  width: 75%;
  /* border-left: 10px solid #ffe100; */
}
.BioEnergetics_bnr_heading_sec::before {
  position: absolute;
  content: '';
  background: url(https://all-frontend-assets.s3.us-east-1.amazonaws.com/transcendentpagan/assets/images/yellow_bar_bfr_img.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 10px;
  height: 195px;
  top: 26%;
  left: 0;
  transform: translateY(-50px);
}
.divine_sec .banner_textblock h2 {
  width: 100%;
  font-size: 45px;
  color: #d5d5d5;
  text-wrap: balance;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px #0e0e0e;
  padding: 0;
  padding-left: 30px;
  margin: 0;
}
.divine_sec .aspireheader {
  font-size: 60px !important;
  line-height: 65px !important;
  font-weight: 700;
}
.switch_sec_wrpr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.divine_slots_booking_page_main .divine_slots_booking_page_main.mainContentWrapper {
  background: #000000;
  padding: 0;
}
.divine_slots_booking_page_main .form_wrapper {
  background: #232324;
  border: 1px solid #464748;
}
.mainContentWrapper.divine_slots_booking_page_main {
  background: #000000;
  padding: 0;
}
.divine_slots_booking_page_main .calender_body {
  background: #232324;
  border: 1px solid #464748 !important;
}
.divine_slots_booking_page_main.form_wrapper .MuiOutlinedInput-root .MuiSelect-outlined {
  color: #9a9999;
  background: #000000;
}
 .form_wrapper .MuiBox-root {
  color: #236bb0;
}
.divine_slots_booking_page_main.form_wrapper .MuiBox-root {
  color: #b7b4b4;
}

.divine_sec.form_wrapper .MuiOutlinedInput-root .MuiSelect-outlined {
  color: #000000;
  background: #ffffff;
  border: 1px solid #484b4b;
  border-radius: 0;
  box-shadow: 1px 1px 6px #000000a6;
}
.divine_slots_booking_page_main .form_wrapper .MuiOutlinedInput-root .MuiSvgIcon-fontSizeMedium {
  color: #9a9999;
}
.divine_slots_booking_page_main .MuiList-padding.MuiMenu-list {
  background: #000000;
  border: 1px solid #484b4b;
}
.divine_slots_booking_page_main .MuiList-padding .MuiMenuItem-root.MuiMenuItem-gutters {
  color: #9a9999;
}
.form_wrapper .MuiBox-root {
  color: #b7b4b4;
}

.divine_slots_booking_page_main .form_wrapper .MuiOutlinedInput-input {
  color: #c6c6c6;
  background: #000000;
  border: 1px solid #484b4b;
  box-shadow: 1px 1px 6px #000000a6;
}
.divine_slots_booking_page_main .ContentWrapper h6 {
  font-size: 30px;
  line-height: 35px;
  font-weight: 500;
  color: #9a9999;
  margin: 35px 0 20px;
}
.divine_slots_booking_page_main .ContentWrapper .calender_block .slotBookBtn {
  display: flex;
  background: linear-gradient(180deg, rgba(85,86,86,1) 0%, rgba(45,45,46,1) 100%);
  border: 1px solid #4c4e50;
  margin: 6px 6px 6px auto;
}
.ContentWrapper .calender_block .slotBookBtn .MuiSvgIcon-fontSizeMedium {
  margin-left: 10px;
}
.divine_slots_booking_page_main .ContentWrapper .calender_block p {
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  color: #c6c6c6;
}




@media screen and (max-width: 1720px) {
  .BioEnergetics_banner1.divine_sec {
    background-size: 675px, 100% 100%, cover;
    background-position: bottom right -185px, top left, center;
  }
  .divine_sec .aspireheader {
    font-size: 50px !important;
    line-height: 55px !important;
  }
  .BioEnergetics_bnr_heading_sec::before {
    height: 165px;
    top: 31%;
  }
}

@media screen and (max-width: 1420px) {
  .user_from_modal .MuiBox-root.css-1wnsr1i {
    width: 85% !important;
  }

  .user_from_modal .modalBox {
    width: 100% !important;
  }

  .modalblock .MuiBox-root h2 {
    width: 100%;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
    width: 100%;
  }

  .modalblock .MuiBox-root input {
    width: 100%;
  }

  .information_details_sec {
    width: 100%;
    margin-top: 10px;
  }

  .note_section_main {
    width: 100%;
  }


  .BioEnergetics_banner1.divine_sec {
    background-size: 635px, 100% 100%, cover;
    background-position: bottom right -198px, top left, center;
  }
  .BioEnergetics_bnr_heading_sec {
    width: 70%;
  }
  .divine_sec .aspireheader {
    font-size: 43px !important;
    line-height: 48px !important;
  }
  .BioEnergetics_bnr_heading_sec::before {
    height: 145px;
    top: 34%;
  }
}

@media screen and (max-width: 1320px) {
  .BioEnergetics_banner1.divine_sec {
    background-size: 635px, 100% 100%, cover;
    background-position: bottom right -198px, top left, center;
  }
  .BioEnergetics_bnr_heading_sec {
    width: 70%;
  }
  .divine_sec .aspireheader {
    font-size: 38px !important;
    line-height: 44px !important;
    padding-left: 20px;
  }
  .BioEnergetics_bnr_heading_sec::before {
    width: 8px;
    height: 145px;
    top: 34%;
  }
}

@media screen and (max-width: 1199px) {
  .BioEnergetics_banner1.divine_sec {
    background: url(https://all-frontend-assets.s3.us-east-1.amazonaws.com/transcendentpagan/assets/images/divine_slots_booking_top_bnr_bg_img.webp), #000000;
    background-repeat: no-repeat;
    background-size: cover, cover;
    background-position: top left, center;
  }
  .BioEnergetics_bnr_heading_sec {
    width: 100%;
  }
  .divine_sec .aspireheader {
    font-size: 35px !important;
    line-height: 40px !important;
    padding-left: 0 !important;
  }
  .BioEnergetics_bnr_heading_sec::before {
    display: none;
  }
}

@media screen and (max-width: 999px) {
  .from_sec_main_wrpr {
    flex-direction: column;
  }

  .user_from_main_wrpr {
    flex-direction: column;
  }

  .from_field {
    width: calc(100% - 12px);
  }

  .information_details_sec.prsnl_infrm_main {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .from_field .relation_status_wrpr {
    justify-content: flex-start;
  }


  .divine_sec .aspireheader {
    font-size: 30px !important;
    line-height: 35px !important;
  }
}

@media screen and (max-width: 499px) {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
    min-width: 100%;
  }

  .from_field {
    width: 100%;
    margin: 10px 0;
  }

  .from_field .MuiFormControl-root {
    margin: 0;
  }

  .from_field .MuiFormControlLabel-root {
    margin-bottom: 10px;
  }


  .banner_textblock .banner_textblock_logo {
    margin-bottom: 10px;
  }
  .divine_sec .aspireheader {
    font-size: 25px !important;
    line-height: 30px !important;
  }
}





/* ///////////////////////////////////////////////////////////////// */

/* -----------BIOGENIX-CSS-START------------ */

.biogenix .fryrightBg1 {
  background: linear-gradient(180deg, rgba(233, 0, 0, 1) 30%, rgba(200, 0, 0, 1) 70%);
}
.biogenix .banner_textblock {
  min-height: 170px;
}
.biogenix .banner_textblock .banner_textblock_logo {
  background: #ffffff;
  padding: 10px;
}
.biogenix .form_wrapper {
  background: #f2f2f2;
  border: 1px solid #e7e7e7;
  box-shadow: 2px 2px 10px #4040403d, -2px -2px 15px #ffffff inset;
}
.biogenix .form_wrapper .form_group label {
  color: #d80000;
}
.biogenix .form_wrapper .form_group label.MuiInputLabel-formControl {
  color: #9a9999;
}
.biogenix .calender_block h2 {
  background: linear-gradient(90deg, rgba(233, 0, 0, 1) 30%, rgba(200, 0, 0, 1) 100%);
}
.biogenix .calender_block p span {
  color: #c80000;
}
.biogenix .calender_block .slotBookBtn {
  font-weight: 600;
  background: linear-gradient(180deg, rgba(232, 9, 22, 1) 35%, rgba(192, 8, 11, 1) 70%);
}
.biogenix .form_wrapper .subBTN Button {
  background: linear-gradient(145deg, rgba(233, 0, 0, 1), rgba(200, 0, 0, 1) 0% 100%);
  box-shadow: 2px 2px 6px #5d5d5d91;
}
.biogenix .calender_body {
  background: #f2f2f2;
  border: 1px solid #e7e7e7;
  box-shadow: 2px 2px 10px #4040403d, -2px -2px 15px #ffffff inset !important;
}


/* ------MODAL-CSS------ */
.biogenix .modalblock .MuiBox-root {
  border: solid 10px #cf1f1f;
}
.biogenix .bookingModal.modalblock .closeModalBtn {
  font-weight: bold;
  background: #cf1f1f !important;
}
.biogenix .modalblock .MuiBox-root h2 {
  background: linear-gradient(180deg, rgba(230, 8, 22, 1) 35%, rgba(203, 4, 17, 1) 70%);
}
.biogenix .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
  background: #f2f2f2;
}
.biogenix .MuiTypography-body1 {
  color: #c80000;
}
.biogenix .modalSUBbtn_wrapper .modalSUBbtn {
  font-weight: 600;
  background: linear-gradient(180deg, rgba(232, 9, 22, 1) 35%, rgba(192, 8, 11, 1) 70%);
}


/* -------BIOGENIX-SUCCESS-CSS------- */
.biogenix .darktheme .thankyou-container {
  background: url(https://all-frontend-assets.s3.us-east-1.amazonaws.com/biogenix-ui/biogenix_clndr_mngmt_success_bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.biogenix .darktheme .thankyou-container .thankyou-block .content h1 {
  text-transform: uppercase;
  background: linear-gradient(180deg, rgba(233, 0, 0, 1) 30%, rgba(200, 0, 0, 1) 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-filter: drop-shadow(0 0 3px #00000036);
  filter: drop-shadow(0 0 3px #00000036);
}
.biogenix .darktheme .thankyou-container .thankyou-block .content p {
  color: #373737;
}

/* -----------BIOGENIX-CSS-END------------ */

/* ///////////////////////////////////////////////////////////////// */




/* ///////////////////////////////////////////////////////////////// */

/* -----------FryRight-CSS-START------------ */

.fryright .form_wrapper {
  background: #f4f6f7;
  border: solid 1px #e0e0e0;
  box-shadow: 0 0 6px #eaeff2;
}
.fryright .form_wrapper .form_group label {
  color: #067a0f;
}
.fryright .form_wrapper .form_group label.MuiInputLabel-formControl {
  color: #000000;
}
.fryright .form_wrapper .subBTN Button {
  text-shadow: 1px 1px 2px #1616164a;
  background: linear-gradient(320deg, rgba(255, 138, 0, 1) 10%, rgba(255, 191, 5, 1) 90%);
}
.fryright .formCls {
  background: #f4f6f7;
  border: solid 1px #e0e0e0;
  box-shadow: 0 0 6px #eaeff2 !important;
}
/* .fryright .calender_block {
  border: solid 1px #33333336 !important;
} */
.fryright .calender_block h2 {
  text-shadow: 1px 1px 2px #1616164a;
  background: linear-gradient(90deg, rgb(255, 138, 0) 10%, rgba(255,191,5,1) 90%);
}
.fryright .calender_block .slotBookBtn {
  font-weight: 600;
  text-shadow: 1px 1px 2px #479c00b0;
  background: linear-gradient(90deg, rgb(45, 204, 0), rgb(137, 220, 3));
  box-shadow: none;
}
.fryright .calender_block p span {
  color: #067a0f;
}

.fryright .MuiLinearProgress-colorPrimary {
  background-color: rgb(255 235 178);
}
.fryright .MuiLinearProgress-root .MuiLinearProgress-bar1Indeterminate {
  background-color: #ff7c00;
}
.fryright .MuiLinearProgress-root .MuiLinearProgress-bar2Indeterminate {
  background-color: #05a00a;
}


/* -----------FryRight-MODAL-CSS----------- */
.fryright .modalblock .MuiBox-root {
  border: 10px solid #ff8a00;
}
.fryright .bookingModal.modalblock .closeModalBtn {
  font-weight: 600;
  text-shadow: 0px 0px 2px #0000005e;
  background: linear-gradient(320deg, rgba(255, 138, 0, 1) 10%, rgba(255, 191, 5, 1) 90%) !important;
  box-shadow: 0 0 6px #00000054;
}
.fryright .modalblock .MuiBox-root h2 {
  width: 100%;
  font-weight: 600;
  text-shadow: 0px 1px 2px #1c1c1c66;
  text-transform: uppercase;
  background: linear-gradient(90deg, rgb(255, 138, 0) 10%, rgba(255,191,5,1) 90%);
}
.fryright .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
  width: 100%;
  background: #f4f6f7;
  border: solid 1px #e0e0e0;
  box-shadow: none;
}
.fryright .MuiTypography-body1 {
  font-weight: 500;
  color: #067a0f;
}
.fryright .modalSUBbtn_wrapper .modalSUBbtn {
  font-weight: 600;
  text-shadow: 1px 1px 2px #479c00b0;
  background: linear-gradient(90deg, rgb(45, 204, 0), rgb(137, 220, 3));
  box-shadow: 0 0 5px rgba(0, 0, 0, .4);
}

@media screen and (max-width: 500px) {
  .fryright .bookingModal.modalblock .MuiBox-root.css-1wnsr1i {
      border: solid 6px #ff8a00;
  }
}


/* -----------FryRight-SUCCESS-PAGE-CSS----------- */
.fryright .darktheme .thankyou-container {
  background: url(https://all-frontend-assets.s3.us-east-1.amazonaws.com/fryright_images/fryright_calender_management_success_bg.webp), #fff1cc;
  background-repeat: no-repeat;
  background-size: cover, cover;
  background-position: top center, center;
}
.fryright .darktheme .thankyou-container .thankyou-block .content h1 {
  display: inline;
  font-size: 35px;
  line-height: 40px;
  color: #292c20;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px #ffffff;
}
.fryright .darktheme .thankyou-container .thankyou-block .content p {
  color: #232818;
}

@media screen and (max-width: 768px) {
  .fryright .darktheme .thankyou-container {
    background-position: top left, center;
  }
}
@media screen and (max-width: 500px) {
  .fryright .darktheme .thankyou-container .thankyou-block .content h1 {
      font-size: 30px;
      line-height: 30px;
  }
}

/* -----------FryRight-CSS-END------------ */

/* ///////////////////////////////////////////////////////////////// */


/* << ----------- Modal Form Sanket CSS --------------- >> */

.addemail {
  height: 58px !important;
    font-size: 18px;
    color: #9a9999;
    background-color: #000000 !important;
    padding: 0 10px !important;
    border-radius: 4px;
}
.divineModal .date_field input {
  height: 58px !important;
  width: 99.7% !important;
}